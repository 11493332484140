import React, { useState, useEffect, useMemo } from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import { Modal, Card, Spin } from 'antd';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { AuditOutlined } from '@ant-design/icons';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { ReportStockSerialSearchForm } from '../../../constants/global/SearchForm';
import { ReportStockSerialColumn } from '../../../constants/global/columnTableForm';
import { GET, GET_BRANCH, GET_REPORT_STOCK_SERIAL_NEW, PUT, GET_PRODUCT_NAME_OPTION } from '../../../services/index';
import { auth } from '../../../utils/firebase';
import { useCurrentState } from '../../../utils/hook';
import { CSVLink } from 'react-csv';
import { buildArrayOfObjectWithNoDulplicateKey, convertStrToFormat } from '../../../functions/fn';
import { exportDefault } from '../../../functions/fn';
import { EditRemarkStockSerialModal } from '../../../components/modal/Stock/EditRemarkStockSerialModal';
import { useGetProductFeature } from '../api/api';
import _ from 'lodash';

const StockSerialReportNew = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useCurrentState(auth);

  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [dataSearch, setDataSearch] = useState({
    branch: '',
    itemName: '',
    type: '',
    serialNumber: '',
    appleStatus: '',
    itemStatus: '',
    serial_status: '',
    remark: '',
  });
  const [optionBranch, setOptionBranch] = useState([]);
  const [branchSearch, setBranchSearch] = useState(null);
  const [itemTypeSearch, setItemTypeSearch] = useState(null);
  const [serialStatusSearch, setSerialStatusSearch] = useState(null);
  const [optionProductName, setOptionProductName] = useState([]);
  const [optionSerialNumber, setOptionSerialNumber] = useState([]);
  // const itemNameSearchRef = useRef(null);
  const [defaultValue, setDefaultValue] = useState({});
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [optionItemType, setOptionItemType] = useState([{ value: 'TOTAL', label: 'ทั้งหมด' }]);

  const [filterTriger, setFilterTriger] = useState(0);
  const [pageRef, setPage] = useState(1)
  const [sizeRef, setSize] = useState(10)
  const [totalSize, setTotalSize] = useState(0);

  let optionSN = [];

  useEffect(async () => {
    const params = new URLSearchParams(window.location.search);
    const branchParams = params.get('branch');
    const itemNameParams = params.get('itemName');
    const itemTypeParams = params.get('itemType');
    const serialStatus = params.get('serialStatus');

    if (user && user.branch) {
      getOption();
      getOptionProduct();
      // setBranchDefault(user.branch === "CENTER" ? "" : user.branch)
      getOptionBranch();
      // setOptionBranchLocalStorage(branchLocalStorage)
      if (itemNameParams) {
        setBranchSearch(branchParams);
        setItemTypeSearch(itemTypeParams);
        // setItemSearch(itemNameParams)
        // itemNameSearchRef = itemNameParams;

        if (serialStatus === '') {
          setSerialStatusSearch(null);
        } else {
          setSerialStatusSearch(serialStatus);
        }

        // localStorage.removeItem('branchReportMonitorStock')
        // localStorage.removeItem('itemTypeReportMonitorStock')
        // localStorage.removeItem('serialStatusReportMonitorStock')

        setDataSearch({
          branch: branchParams,
          itemName: itemNameParams,
          type: itemTypeParams,
          serialNumber: '',
          itemNameStockSerial: itemNameParams,
          branchStockSerial: branchParams,
          appleStatus: '',
          itemStatus: '',
          serial_status: serialStatus,
          serialStatusStockSerial: serialStatus,
        });
      } else {
        setDataSearch({
          branch: user.branch === 'CENTER' ? '' : user.branch,
          itemName: '',
          type: '',
          serialNumber: '',
          itemNameStockSerial: '',
          branchStockSerial: user.branch === 'CENTER' ? '' : user.branch,
          appleStatus: '',
          itemStatus: '',
          serial_status: '',
          serialStatusStockSerial: '',
          remark: '',
        });
      }
    }
  }, [user]);

  const [resultProductFeature, loadingProductFeature] = useGetProductFeature();

  useEffect(() => {
    if (resultProductFeature) {
      getOptionProductFeature(resultProductFeature);
    }
  }, [resultProductFeature]);

  const dataSearchMemo = useMemo(() => ({ ...dataSearch }), [dataSearch, pageRef, sizeRef]);

  useEffect(() => { 
    if (dataSearchMemo) { 
      getStockSerialReport()
    }
  }, [filterTriger])


  const getOptionProduct = async () => {
    setLoading(true);
    try {
      const res = await GET(GET_PRODUCT_NAME_OPTION);

      const { success, data } = res;
      if (success) {
        setOptionProductName(data);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const getOptionProductFeature = async (productFeatureDt) => {
    if (productFeatureDt?.length > 0) {
      const productFeatureArray = productFeatureDt?.map((item) => ({ label: item?.name, value: item?.code }));
      setOptionItemType([...optionItemType, ...productFeatureArray]);
    }
  };

  const getOption = async () => {
    setLoading(true);
    try {
      const res = await PUT(GET_REPORT_STOCK_SERIAL_NEW, {
        branch: user.branch === 'CENTER' ? '' : user.branch,
        itemName: '',
        type: '',
        serialNumber: '',
        appleStatus: '',
        itemStatus: '',
        serial_status: '',
        remark: '',
      });
      const { success, data } = res;
      if (success) {
        await buildArrayOfObjectWithNoDulplicateKey('serialNumber', data).map((val) => {
          optionSN.push({
            value: val.serialNumber,
            label: val.serialNumber,
          });
        });

        setOptionSerialNumber(optionSN);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const optionAppleStatus = [
    { label: 'DEP', value: 'DEP' },
    { label: 'Non-DEP', value: 'NON_DEP' },
    { label: 'TBD', value: 'TBD' },
  ];

  const optionItemStatus = [
    { label: 'อยู่ระหว่างส่ง', value: 'IN_TRANSFER' },
    { label: 'ในสต็อก', value: 'IN_STOCK' },
  ];

  const optionSerialStatus = [
    { value: 'TOTAL', label: 'ทั้งหมด' },
    { label: 'พร้อมขาย', value: 'READY' },
    { label: 'ไม่พร้อมขาย', value: 'NOT_READY' },
  ];

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const { success, result } = res;
      if (success) {
        const option = result.branch.map((el) => {
          return {
            label: el.label,
            value: el.value,
          };
        });

        const optionCheck =
          user.branch === 'CENTER'
            ? option
            : option.filter((el) => {
              if (el.value === user.branch) {
                return {
                  label: el.label,
                  value: el.value,
                };
              }
            });

        setOptionBranch([...optionCheck, { value: '', label: 'ทุกสาขา' }]);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const convertBranchName = (value) => {
    let branch_name = optionBranch.filter((el) => el.value === value);
    return branch_name[0].label;
  };

  const getStockSerialReport = async () => {
    const { branch, itemName, type, serialNumber, appleStatus, itemStatus, serial_status, remark } = dataSearch;

    localStorage.removeItem('branchReportMonitorStock');
    // localStorage.removeItem('itemTypeReportMonitorStock')
    localStorage.removeItem('serialStatusReportMonitorStock');

    setLoading(true);
    if (window.location.href.includes('?')) {
      window.history.pushState({}, null, window.location.href.split('?')[0]);
    }
    try {
      let res = await PUT(GET_REPORT_STOCK_SERIAL_NEW, {
        branch: branch,
        itemName: itemName,
        type: type,
        serialNumber: serialNumber,
        appleStatus: appleStatus,
        itemStatus: itemStatus,
        serial_status: serial_status,
        remark: remark,
        paginations: {
          page: pageRef,
          size: sizeRef,
        }
      });
      const { success, length, data } = res;
      if (success) {
        if (length > 0) {
          setTotalSize(length);
          const dataEx = data.map((el, i) => {
            return {
              สาขา: el.branch,
              ชื่อสินค้า: el.itemName,
              'S/N': el.serialNumber,
              ประเภทสินค้า: el.type,
              'Apple Status': el.appleStatus,
              สถานะ: el.itemStatus,
              'Serial Status':
                el.serial_status && el.serial_status === 'NOT_READY' ? 'ไม่พร้อมขาย' : el.serial_status === 'READY' ? 'พร้อมขาย' : el.serial_status,
              หมายเหตุ: el?.remark || '',
            };
          });

          const report = data.map((el, i) => {
            return {
              key: i + 1,
              ...el,
            };
          });

          const dataExportDefault = exportDefault(['สาขา', 'ชื่อสินค้า', 'S/N', 'ประเภทสินค้า', 'Apple Status', 'สถานะสินค้า']);
          setDataExport((dataEx.length > 0 && dataEx) || dataExportDefault);
          setDataSource(report);
        } else {
          setDataSource([]);
        }
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onSearch = async (value) => {
    console.log('value: ', value);
    value.branch = value?.branch || (user.branch !== 'CENTER' ? user.branch : '');
    value.itemName = value?.itemName || '';
    value.type = value?.type || '';
    value.serialNumber = value?.serialNumber || '';
    value.appleStatus = value?.appleStatus || '';
    value.itemStatus = value?.itemStatus || '';
    value.serial_status = value?.serial_status || '';
    value.remark = value?.remark || '';

    if (value.serial_status === 'TOTAL') {
      value.serial_status = '';
    }

    if (value.type === 'TOTAL') {
      value.type = '';
    }
    setDataSearch(value);
    setPage(1);
    setFilterTriger(filterTriger + 1);
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.forEach(({ name }) => setValue(name, null));
    if (window.location.href.includes('?')) {
      window.history.pushState({}, null, window.location.href.split('?')[0]);
    }
    setBranchSearch(null); 
    setPage(1);
    setItemTypeSearch(null);
    setSerialStatusSearch(null);
    setDataSearch({
      branch: '',
      itemName: '',
      type: '',
      serialNumber: '',
      itemNameStockSerial: '',
      branchStockSerial: '',
      appleStatus: '',
      itemStatus: '',
      remark: '',
    }); 
    setFilterTriger(filterTriger + 1);
  };

  const setField = (data, ref) => {
    if (ref === 'branch') {
      if (data === undefined) {
        data = null;
      }
      setBranchSearch(data);
      setDataSearch({ ...dataSearch, branch: data, branchStockSerial: data });
    } else if (ref === 'itemName') {
      if (data === undefined) {
        data = null;
      }

      // setItemSearch(data)
      // itemNameSearchRef = data;
      setDataSearch({ ...dataSearch, itemName: data, itemNameStockSerial: data });
    } else if (ref === 'serial_status') {
      if (data === undefined) {
        data = null;
      }
      setSerialStatusSearch(data);
      setDataSearch({ ...dataSearch, serial_status: data, serialStatusStockSerial: data });
    }
  };

  const openModal = (record, name) => {
    if (record) {
      setDefaultValue(record);
      if (name === 'EDIT_REMARK') {
        setVisibleEditModal(true);
      }
    }
  };

  const fetchDataForExport = async () => {
    setLoading(true);

    try {
      const dataEx = dataSource.map((el) => {
        return {
          สาขา: (el.branch && convertBranchName(el.branch)) || '',
          ชื่อสินค้า: el.itemName,
          'S/N': el.serialNumber,
          ประเภทสินค้า: el.type,
          'Apple Status': el.appleStatus,
          สถานะ: el?.itemStatus && el.itemStatus === 'IN_TRANSFER' ? 'อยู่ระหว่างส่ง' : el.itemStatus === 'IN_STOCK' ? 'ในสต็อก' : '',
          'Serial Status':
            el.serial_status && el.serial_status === 'NOT_READY' ? 'ไม่พร้อมขาย' : el.serial_status === 'READY' ? 'พร้อมขาย' : el.serial_status,
          'วันที่รับเข้า': el.create_date ? el.create_date : '',
          หมายเหตุ: el?.remark || '',
        };
      });

      setDataExport(dataEx);
    } catch (err) {
      const { message, error } = err;

      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setLoading(false);
      setVisibleModal(true);
    }
  };

  const onClose = () => {
    setLoading(false);
    setVisibleModal(false);
  };

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    setPage(pageData);
    setSize(sizeData); 
    setFilterTriger(!filterTriger);
  };


  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading || loadingProductFeature}>
          <PageLayout
            searchLayout={{
              title: 'รายงานสต็อกแยกตาม Serial',
              icon: <AuditOutlined />,
              spanSearch: 24,
              formSearch: ReportStockSerialSearchForm({
                data: { branchSearch, itemTypeSearch, serialStatusSearch },
                option: {
                  optionBranch,
                  optionItemType,
                  optionProductName,
                  optionSerialNumber,
                  optionAppleStatus,
                  optionItemStatus,
                  optionSerialStatus,
                },
                fn: { setField },
              }),
              onSearch: (data) => onSearch(data),
              dataSearchDefault: dataSearch,
              onClearCustom,
            }}
            tableLayout={{
              columns: ReportStockSerialColumn({ options: { optionBranch, optionItemStatus, optionItemType, optionAppleStatus }, fn: { openModal } }),
              dataSource: dataSource,
              extraContent: (
                <ContainerButton right>
                  <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => fetchDataForExport()} />
                  {/* <CSVLink data={dataExport} filename={"รายงานสต็อกแยกตาม Serial"}><ButtonTheme useFor="DOWNLOAD" title={'EXPORT'} /></CSVLink> */}
                </ContainerButton>
              ),
              pagination: {
                showSizeChanger: true,
                current: pageRef,
                pageSize: sizeRef,
                onChange: (p, s) => onPageSizeChangeCustom(p, s),
                pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
                total: totalSize || 0,
                showTotal: (totalSize) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: sizeRef,
                defaultCurrent: 1,
              },
            }}
          />
        </Spin>
      </Card>
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <CSVLink data={dataExport} filename={'รายงานสต็อกแยกตาม Serial'}>
            <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={() => onClose()} />
          </CSVLink>
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>
      <EditRemarkStockSerialModal
        visible={visibleEditModal}
        title={'แก้ไขหมายเหตุ'}
        onClose={() => setVisibleEditModal(false)}
        data={{ reApi: getStockSerialReport, record: defaultValue }}
      />
    </Wrapper>
  );
};

export default StockSerialReportNew;
