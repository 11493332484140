import moment from 'moment';
import numeral from 'numeral';
import {Tag} from 'antd';
import {API_URL, API_URL_RESEVRE} from '../env';
import {CONTRACT_STATUS, SENDING_STATUS, TRACKING_STATUS, TRACKING_RECORD_STATUS, STATUS, TERMINATE_CONTRACT} from '../constants/status/status';
import _ from 'lodash';

const CryptoJS = require('crypto-js');
const KEY = '183yhf83hwf';

Number.prototype.pad = function (size) {
  let s = String(this);
  while (s.length < (size || 2)) {
    s = '0' + s;
  }
  return s;
};

/**
 *
 * TODO ฟังก์ชั่นเช็คตัวเลข
 */
export const containsNumbers = (str) => {
  return /\d/.test(str);
};

/**
 *
 * TODO ฟังก์ชั่นสำหรับดึงชื่อ-นามสกุล
 */
export const customerName = (firstname, lastname) => {
  return `${firstname}-${lastname}`;
};

/**
 *
 * TODO ฟังก์ชั่นทำดีเลย์
 */
export const delay = (ms = 500) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 *
 * TODO ฟังก์ชั่นสำหรับเช็คค่าว่าง
 */
// export function isEmpty(array) {
//   let obj = array;
//   if (!Array.isArray(obj) && typeof obj === 'object') {
//     obj = Object.values(obj);
//   }
//   return _.some(
//     obj.map((el) => el && el.toString()),
//     _.isEmpty,
//   );
// }

export const convertStrToFormat = (str, format) => {
  if (!str) {
    return '';
  }

  switch (format) {
    case 'phone_number':
      str = str.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      break;
    case 'number_only':
      str = str.replace(/\D/g, '');
      break;
    case 'eng_only':
      str = str.replace(/[ก-๛]/g, '');

      break;
    case 'id_card':
      str = str.replace(/(\d{1})(\d{2})(\d{5})(\d{2})(\d{2})(\d{1})/, '$1-$2-$3-$4-$5-$6');
      break;
    case 'credit':
      str = str.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');
      break;
    case 'money':
      str = numeral(str).format('0,0[.]00');
      break;
    case 'money_digit':
      str = numeral(str).format('0,0.00');
      break;
    case 'date':
      const [d, m, y] = moment(str).format('DD-MM-YYYY').split('-');
      str = `${d}-${m}-${+y + 543}`;
      break;
    case 'dateAD':
      str = moment(str).format('YYYY-MM-DD');
      break;
    case 'dateFor':
      str = moment(str).format('DD/MM/YYYY');
      break;
    case 'dateFor2':
      str = moment(str).format('YYYY/MM/DD');
      break;
    case 'datetime':
      str = moment(str).format('YYYY-MM-DD HH:mm');
      break;
    case 'datetime-th':
      str = moment(str).add(543, 'y').format('YYYY-MM-DD HH:mm');
      break;
    case 'datetime-second-th-slash':
      str = moment(str).add(543, 'y').format('DD/MM/YYYY HH:mm:ss');
      break;
    case 'dateFor-th':
      str = moment(str).add(543, 'y').format('DD/MM/YYYY');
      break;
    case 'dayDatetime':
      str = moment(str).add(543, 'y').format('llll');
      break;
    case 'timetozero-utc':
      str = moment
        .utc(str)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .format();
      break;
    case 'price':
      str = numeral(str).value();
      break;
    case 'dateISO-set-time-0':
      let temp = moment(str).toISOString().split('T');
      let newTemp = temp[0] + 'T00:00:00.000Z';
      str = newTemp;
      break;
    case 'number-comma':
      str = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      break;
    default:
      break;
  }

  return str;
};

export const convertFormatToStr = (str) => {
  if (!str) {
    return '';
  }
  str = `${str}`.replace(/-|,/g, '');
  return str;
};

export const covertStringTH = (str) => {
  if (!str) {
    return '';
  }
  const sss = str.toString().replace(/[กขฃคฅฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรฤลฦวศษสหฬอฮฯะัาำิีึืฺุูเแโใไๅๆ็่้๊๋์]/, '');

  return str;
};

export function compareRender(prevProps, nextProps, key = 'value') {
  return prevProps[key] === nextProps[key];
}

export function convertMomentFormat(date, format) {
  if (!date && !format) return '';
  return moment(date).format(format);
}

export function validateBetweenDate(dateStart, dateEnd) {
  if (!dateStart && !dateEnd) return 'must be not empty values.';
  if (moment(dateStart, 'YYYY-MM-DD').isAfter(moment(dateEnd, 'YYYY-MM-DD'))) {
    return 'วันที่เริ่มต้นต้องน้อยกว่าวันที่สิ้นสุด';
  } else {
    return '';
  }
}

export function convertBaht(value) {
  if (value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

export function getFullYear(value = new Date()) {
  return (value || new Date()).getFullYear();
}

export function getMonthOption(value = new Date()) {
  return (value || new Date()).getMonth().toString().padStart(2, '0');
}

// export function checkClearDataLogout() {
//   removeCookies("token_fp");
//   removeCookies("customerName");
//   if (!getCookies("token_fp")) {
//     new UserMobx().setCheckLogin(false);
//     // new UserMobx().setUser({});
//     //return route(`/account/login`);
//     window.location = "https://central.zetta-system.com/account/login";
//   }
// }

export const getNameFile = (file) => {
  if (file) {
    let split_file = file.split('/');

    return split_file[split_file.length - 1];
  }
  return '';
};

export const getPathFile = (file) => {
  if (file) {
    let split_file = file.url.split('/');
    return `/${split_file[split_file.length - 2]}/${split_file[split_file.length - 1]}`;
  }
  return '';
};

export const checkDisableDate = (d, date) => {
  const today = moment(date).format('YYYY-MM-DD');
  return moment(d).isSameOrBefore(today);
};

export const duplicateData = (data) => {
  if (data.length > 0) {
    return data.filter((el, id) => id === data.findIndex((ele) => ele.value === el.value));
  }
};

export const filterDataAddress = (data, value, ref) => {
  if (ref === 'province') {
    return data.map((val) => {
      return {
        value: val.province,
        label: val.province,
      };
    });
  } else if (ref === 'amphoe') {
    return data
      .filter((val) => val.province === value)
      .map((prop) => {
        return {value: prop.amphoe, label: prop.amphoe};
      });
  } else if (ref === 'district') {
    return data
      .filter((val) => val.amphoe === value)
      .map((prop) => {
        return {value: prop.district, label: prop.district};
      });
  }
};

export const clearSearchForm = (setValue, form) => {
  form.map((val) => {
    if (val.name === 'startDate') {
      setValue(val.name, moment().startOf('month'));
    } else if (val.name === 'endDate') {
      setValue(val.name, moment().endOf('month'));
    } else if (val.name === 'brand' || val.name === 'warehouse' || val.name === 'location') {
      setValue(val.name, null);
    } else {
      setValue(val.name, '');
    }
  });
};

export const countStr = (number, value) => {
  if (value.length > number) {
    return `${value.slice(0, number)}...`;
  } else {
    return value;
  }
};

export const checkPassword = (str) => {
  const uppercase = /[A-Z]/;
  const lowercase = /[a-z]/;
  const special = /([!#$%&()*+/<=>?@\[\]^{}])/;
  const numbers = /[0-9]/;

  return uppercase.test(str) && lowercase.test(str) && special.test(str) && numbers.test(str);
};

export const encryptString = (str) => {
  return CryptoJS.AES.encrypt(str, KEY).toString();
};

export const decryptString = (encodeStr) => {
  return CryptoJS.AES.decrypt(encodeStr, KEY).toString(CryptoJS.enc.Utf8);
};

export const countOfDigits = (str) => {
  return str.split('').length;
};

export const checkTrackingNo = (str) => {
  const trackingno = /^[a-zA-Z]+(\d{9})+[a-zA-Z]/;
  return trackingno.test(str);
};

export const statusTagStyle = (status, useFor, style) => {
  const tagStyle = {borderRadius: '5px', marginRight: -2, ...style};
  switch (useFor) {
    case 'contractStatus': {
      switch (status) {
        case CONTRACT_STATUS.active.en:
          return (
            <Tag color="gold" style={tagStyle}>
              {CONTRACT_STATUS.active.th}
            </Tag>
          );
        case CONTRACT_STATUS.terminated.en:
          return (
            <Tag color="red" style={tagStyle}>
              {CONTRACT_STATUS.terminated.th}
            </Tag>
          );
        case CONTRACT_STATUS.impounded.en:
          return (
            <Tag color="red" style={tagStyle}>
              {CONTRACT_STATUS.impounded.th}
            </Tag>
          );
        case CONTRACT_STATUS.completed.en:
          return (
            <Tag color="green" style={tagStyle}>
              {CONTRACT_STATUS.completed.th}
            </Tag>
          );
        case CONTRACT_STATUS.notice_of_termination.en:
          return (
            <Tag color="green" style={tagStyle}>
              {CONTRACT_STATUS.notice_of_termination.th_short}
            </Tag>
          );
        default:
          return;
      }
    }
    case 'sendingStatus': {
      switch (status) {
        case SENDING_STATUS.notSend.en:
          return (
            <Tag color="red" style={tagStyle}>
              {SENDING_STATUS.notSend.th}
            </Tag>
          );
        case SENDING_STATUS.sending.en:
          return (
            <Tag color="gold" style={tagStyle}>
              {SENDING_STATUS.sending.th}
            </Tag>
          );
        case SENDING_STATUS.arrived.en:
          return (
            <Tag color="green" style={tagStyle}>
              {SENDING_STATUS.arrived.th}
            </Tag>
          );
        case SENDING_STATUS.failed.en:
          return (
            <Tag color="red" style={tagStyle}>
              {SENDING_STATUS.failed.th}
            </Tag>
          );
        case SENDING_STATUS.returning.en:
          return (
            <Tag color="gold" style={tagStyle}>
              {SENDING_STATUS.returning.th}
            </Tag>
          );

        case SENDING_STATUS.wait_sending.en:
          return (
            <Tag color="gold" style={tagStyle}>
              {SENDING_STATUS.wait_sending.th}
            </Tag>
          );
        case SENDING_STATUS.return_success.en:
          return (
            <Tag color="blue" style={tagStyle}>
              {SENDING_STATUS.return_success.th}
            </Tag>
          );
        default:
          return;
      }
    }
    case 'trackingTaskStatus': {
      switch (status) {
        case TRACKING_STATUS.normal.en:
          return;
        case TRACKING_STATUS.notice_of_termination.en:
          return (
            <Tag color="gold" style={tagStyle}>
              {TRACKING_STATUS.notice_of_termination.th}
            </Tag>
          );
        case TRACKING_STATUS.field_collection.en:
          return (
            <Tag color="gold" style={tagStyle}>
              {TRACKING_STATUS.field_collection.th_short}
            </Tag>
          );
        case TRACKING_STATUS.hold.en:
          return (
            <Tag color="green" style={tagStyle}>
              {TRACKING_STATUS.hold.th}
            </Tag>
          );
        case TRACKING_STATUS.closed.en:
          return (
            <Tag color="green" style={tagStyle}>
              {TRACKING_STATUS.closed.th}
            </Tag>
          );
        case TRACKING_STATUS.sue.en:
          return (
            <Tag color="red" style={tagStyle}>
              {TRACKING_STATUS.sue.th_short}
            </Tag>
          );
        case TRACKING_STATUS.baddebt.en:
          return (
            <Tag color="red" style={tagStyle}>
              {TRACKING_STATUS.baddebt.th}
            </Tag>
          );
        case TRACKING_STATUS.cancel.en:
          return (
            <Tag color="red" style={tagStyle}>
              {TRACKING_STATUS.cancel.th}
            </Tag>
          );
        case TRACKING_STATUS.finished.en:
          return (
            <Tag color="green" style={tagStyle}>
              {TRACKING_STATUS.finished.th}
            </Tag>
          );
        default:
          return;
      }
    }
    case 'trackingRecordStatus': {
      switch (status) {
        case TRACKING_RECORD_STATUS.close.en:
          return (
            <Tag color="green" style={tagStyle}>
              {TRACKING_RECORD_STATUS.close.th}
            </Tag>
          );
        case TRACKING_RECORD_STATUS.return.en:
          return (
            <Tag color="green" style={tagStyle}>
              {TRACKING_RECORD_STATUS.return.th}
            </Tag>
          );
        case TRACKING_RECORD_STATUS.appointment.en:
          return (
            <Tag color="blue" style={tagStyle}>
              {TRACKING_RECORD_STATUS.appointment.th}
            </Tag>
          );
        case TRACKING_RECORD_STATUS.not_tracked.en:
          return (
            <Tag color="red" style={tagStyle}>
              {TRACKING_RECORD_STATUS.not_tracked.th}
            </Tag>
          );
        case TRACKING_RECORD_STATUS.not_found.en:
          return (
            <Tag color="red" style={tagStyle}>
              {TRACKING_RECORD_STATUS.not_found.th}
            </Tag>
          );
        case TRACKING_RECORD_STATUS.finished.en:
          return (
            <Tag color="green" style={tagStyle}>
              {TRACKING_RECORD_STATUS.finished.th}
            </Tag>
          );
        case TRACKING_RECORD_STATUS.other.en:
          return (
            <Tag color="gold" style={tagStyle}>
              {TRACKING_RECORD_STATUS.other.th}
            </Tag>
          );
        default:
          return;
      }
    }
    case 'status': {
      switch (status) {
        case STATUS.special.en:
          return (
            <Tag color="gold" style={tagStyle}>
              {STATUS.special.th}
            </Tag>
          );
        case STATUS.normal.en:
          return (
            <Tag color="blue" style={tagStyle}>
              {STATUS.normal.th}
            </Tag>
          );

        default:
          return (
            <Tag color="blue" style={tagStyle}>
              {STATUS.normal.th}
            </Tag>
          );
      }
    }
    case 'terminateContract': {
      switch (status) {
        case TERMINATE_CONTRACT.sale_phone.en:
          return (
            <Tag color="red" style={tagStyle}>
              {TERMINATE_CONTRACT.sale_phone.th}
            </Tag>
          );
        case TERMINATE_CONTRACT.change_password.en:
          return (
            <Tag color="red" style={tagStyle}>
              {TERMINATE_CONTRACT.change_password.th}
            </Tag>
          );
        case TERMINATE_CONTRACT.other.en:
          return (
            <Tag color="red" style={tagStyle}>
              {TERMINATE_CONTRACT.other.th}
            </Tag>
          );
        default:
          return (
            <Tag color="default" style={tagStyle}>
              ผิดเงื่อนไขเกิน 60 วัน
            </Tag>
          );
      }
    }
    default:
      return;
  }
};

export const exportDefault = (columns) => {
  let obj = {};
  if (columns.length > 0) {
    columns.map((el) => {
      obj[el] = '';
    });
  }

  return [obj];
};
export const mergeObjectData = (item) => {
  const result = {};
  item.forEach((sum) => {
    for (let [key, value] of Object.entries(sum)) {
      if (result[key]) {
        result[key] += value;
      } else {
        result[key] = value;
      }
    }
  });
  return result;
};

export const buildArrayOfObjectWithNoDulplicateKey = (key, arr) => {
  const findKey = key;
  const unique = [...new Map(arr.map((item) => [item[key], item])).values()];
  return unique;
};

export const generatedOptionWithData = (op, title) => {
  const list = op.map((e) => e[title]);
  const set = Array.from(new Set(list));
  const selectOptions = set.map((on) => ({
    label: on,
    value: on,
  }));
  return selectOptions;
};

export const splitTextMultipleToken = (str, tokens) => {
  var tempChar = tokens[0];
  for (var i = 1; i < tokens.length; i++) {
    str = str.split(tokens[i]).join(tempChar);
  }
  str = str.split(tempChar);
  return str;
};

export const switchApiUrl = (key) => {
  let url = '';
  switch (true) {
    case key.includes('www'):
      url = API_URL_RESEVRE;
      break;
    case key.includes('2030'):
      url = API_URL;
      break;
    case key.includes('3000'):
      url = API_URL;
      break;
    case key.includes('localhost'):
      url = API_URL;
      break;
    default:
      url = API_URL;
      break;
  }
  return url;
};

export const checkSameNameWithPrefix = (nameUser, bookBankName) => {
  const pattern = /(ํา)/g;
  const trimmedNameUser = nameUser.trim();
  const trimmedBookBankName = bookBankName.trim();
  let splittedNameUser = trimmedNameUser.split(' ');
  let splittedBookBankName = trimmedBookBankName.split(' ');
  splittedNameUser = splittedNameUser.map((eachNameUser) => eachNameUser.trim());
  splittedBookBankName = splittedBookBankName.map((eachBookBankName) => eachBookBankName.trim());

  // for (let i = splittedNameUser.length - 1; i >= 0; i--) {
  // 	const differentLength = splittedBookBankName.length - splittedNameUser.length;
  // 	if (!splittedBookBankName[i + differentLength].includes(splittedNameUser[i])) return false;
  // }
  let mergeNameUserwithoutPrefix = '';
  let mergeBookBankNamewithoutPrefix = '';
  for (let i = splittedNameUser.length - 1; i >= 0; i--) {
    const differentLength = splittedBookBankName.length - splittedNameUser.length;
    mergeNameUserwithoutPrefix += splittedNameUser[i];
    mergeBookBankNamewithoutPrefix += splittedBookBankName[i + differentLength];
  }

  mergeBookBankNamewithoutPrefix = mergeBookBankNamewithoutPrefix.replace(pattern, 'ำ');
  if (mergeNameUserwithoutPrefix !== mergeBookBankNamewithoutPrefix) {
    return false;
  }

  return true;
};

export const isNegative = (num) => {
  if (Math.sign(num) === -1) {
    return true;
  }

  return false;
};

export const cutStringImgSigned = (url) => {
  let result = '';
  if (url) {
    result = url.split('?');
  }
  return result[0] || '';
};

const getBase64StringFromDataURL = (dataURL) => dataURL.replace('data:', '').replace(/^.+,/, '');

export async function getBase64FromUrl(url, cb) {
  const data = await fetch(url);
  let result = '';
  const blob = await data.blob();
  const reader = new FileReader();
  const base64 = '';
  if (data.status === 200) {
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      result = base64data;
    };
    return reader;
  }
}

export const checkMultipleIncludedValue = (value) => {
  let result = '';

  let string = ['jpg', 'jpeg', 'png'];
  const res = string.reduce((a, c) => a + value.includes(c), 0) == 1;
  let test1 = '';
  if (res) {
    string.map((el, i) => {
      let re = value.indexOf(el) > 1;
      if (re) {
        test1 = el;
      }
    });
  }

  result = `image/${test1}`;

  return result;
};

export const checkIdCardPattern = (id) => {
  if (id.substring(0, 1) === 0) return false;
  if (id.length !== 13) return false;
  let sum = 0;
  for (let i = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i);
  if ((11 - (sum % 11)) % 10 !== parseFloat(id.charAt(12))) return false;
  return true;
};

export const openPDF = (url) => {
  let html = '';

  html += '<html>';
  html += '<body style="margin:0!important">';
  html += '<embed width="100%" height="100%" src="' + url + '" type="application/pdf" />';
  html += '</body>';
  html += '</html>';
  return html;
};

export const removeNewlineText = (value) => {
  let text = value;
  if (value && value !== '') {
    text = JSON.stringify(text);
    text = text.replaceAll('\\n', ' ');
    text = text.replaceAll('\n', ' ');
    text = text.replaceAll('\\', ' ');
    // text = text.replaceAll(/"/g, ' ');
    text = text.replaceAll(/["]/g, '');
  }
  return text;
};

export const parseNumber = (str) => {
  if (!str) return null;

  if (typeof str === 'string') {
    return parseFloat(str.replaceAll(',', ''));
  }

  return str;
};

export const getAgeMonth = (dateString) => {
  // ใช้ปี ค.ศ. คิด
  const birthDate = new Date(dateString);
  const today = new Date();

  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();

  // Adjust years and months if the current month is before the birth month
  if (months < 0) {
    years--;
    months += 12;
  }

  // Adjust months if the current day is before the birth day
  if (today.getDate() < birthDate.getDate()) {
    months--;
    if (months < 0) {
      years--;
      months += 12;
    }
  }

  return {years, months};
};

export const monthOnly = (monthNumber) => {
  const months = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ];

  if (monthNumber <= 12) {
    return months[monthNumber];
  } else {
    return 'Invalid Month';
  }
};

export const monthShortOnly = (monthNumber) => {
  const months = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
  if (monthNumber <= 12) {
    return months[monthNumber];
  } else {
    return 'Invalid Month';
  }
};

export const checkNumberExist = (value) => {
  // เช็คตัวเลขใน string ex. checkNumberExist('1-1245-5455') => true
  return /\d/.test(value);
};


export const getMonthMinusMonths = (date, months) => {
  const result = new Date(date);
  result.setMonth(result.getMonth() - months);
  if (result.getDate() !== date.getDate()) {
    result.setDate(0); // ตั้งค่าเป็นวันสุดท้ายของเดือนก่อนหน้า
  }
  return result;
}

