import _ from 'lodash';
import {notification} from 'antd';
import {v4 as uuidv4} from 'uuid';
import {Redirect} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Path as subPath} from '../../utils/subdomain';
import LoadingComp from '../../components/loading/Loading';
import GoogleIcon from '../../components/svg-icons/Google';
import {WrapperLogin, ImgLogo, NamePage, Landing, ButtonLogin} from './LoginStyle';
import {useAuthState} from '../../utils/hook';
import {auth, firebase} from '../../utils/firebase';
import {adminLogin, checkEmail} from '../../redux/actions/auth';
import {encryptString} from '../../functions/fn';
import {getAccessToken} from './api';
import { decodeUserAdmin } from '../../oauth';

const Login = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [authenticatedV2, setAuthenticatedV2] = useState(false);
  const {user} = useAuthState(auth);

  const email = useSelector((state) => state.email?.email, shallowEqual);
  const authenticated = useSelector((state) => state.authenticate?.isAuthenticate, shallowEqual);

  useEffect(() => {
    if (email && user) {
      /* call login with admin original */
      const loginAdmin = async () => {
        const loginAdminRes = await dispatch(adminLogin(user));
        if (loginAdminRes?.error) openNotification(loginAdminRes.error);

        /* call login with admin version 2 */
        if (loginAdminRes?.token) {
          const clientKey = localStorage.getItem('clientKey') || uuidv4();
          if (!localStorage.getItem('clientKey')) localStorage.setItem('clientKey', clientKey);

          const response = await getAccessToken(loginAdminRes?.token, clientKey);

          /* error case */
          if (response?.data?.errors) openNotification(_.head(response?.data?.errors)?.message);

          /* successfully */
          if (response?.data?.data) {
            const dt = response?.data?.data?.adminLoginWithToken;
            const userAdminV2 = decodeUserAdmin()
            const setDt = {...userAdminV2, accessToken: dt?.accessToken, refreshToken: dt?.refreshToken, clientKey};

            const enCryptUserAdmin = encryptString(JSON.stringify(setDt));
            localStorage.setItem('userAdminV2', enCryptUserAdmin);
            setAuthenticatedV2(true);
          }
        }

        setLoading(false);
      };

      /* call login admin function */
      loginAdmin();
    }
  }, [email, user]);

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();

    try {
      let ans = await firebase.auth().signInWithPopup(provider);

      if (ans.user?.email) {
        setLoading(true);
        dispatch(checkEmail(ans.user.email));
      }
    } catch (err) {
      openNotification(err.message);
    }
  };

  const openNotification = (message) => {
    notification['error']({
      message: 'เข้าสู่ระบบไม่สำเร็จ',
      description: message,
    });
  };

  if (user && email && authenticated && authenticatedV2) {
    // Start For Adminv2 Web
    const token = localStorage.getItem('token');
    const setUserAdmin = {...user, adminToken: token};

    const enCryptUserAdmin = encryptString(JSON.stringify(setUserAdmin));
    localStorage.setItem('userAdmin', enCryptUserAdmin);
    // End For Adminv2 Web

    return <Redirect to={subPath.Dashboard} />;
  }

  return (
    <>
      {loading ? (
        <LoadingComp />
      ) : (
        <WrapperLogin>
          <Landing>
            <ImgLogo src={'/dist/img/app-logo.png'} alt="landing" />
            <NamePage>Ufriend Administrator</NamePage>
            <ButtonLogin onClick={signInWithGoogle}>
              <GoogleIcon />
              <p>Login with Google</p>
            </ButtonLogin>
          </Landing>
        </WrapperLogin>
      )}
    </>
  );
};

export default Login;
