// /* eslint no-use-before-define: 0 */
import React, { useState, useEffect, useContext } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Button } from 'antd';
import { ManageUserColumn } from '../../constants/global/columnTableForm';
import { PlusOutlined } from '@ant-design/icons';
import { color } from '../../resources/color';
import { CreateUserModal } from '../../components/modal/ManageUser/CreateUserModal';
import { EditUserModal } from '../../components/modal/ManageUser/EditUserModal';
import { GET, POST, GET_ALL_USER_ADMIN, EDIT_USER_ADMIN, GET_OPTION_BRANCH } from '../../services/index';
// import {socketContext} from '../../utils/socketProvider';
import { auth } from '../../utils/firebase';
import { useCurrentState } from './../../utils/hook';
import TableForm from '../../components/table/';

const ManageUser = () => {
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [title, setTitle] = useState('');
  const [valueDefault, setValueDefault] = useState({});
  // const {socket} = useContext(socketContext);
  const { user } = useCurrentState(auth);
  const [optionBranch, setOptionBranch] = useState([]);

  useEffect(() => {
    getAllUserAdmin();
    getOptionBranch();
  }, []);

  const getOptionBranch = async () => {
    try {
      let res = await GET(GET_OPTION_BRANCH);
      const { success, result } = res;
      if (success) {
        setOptionBranch(result);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const getAllUserAdmin = async () => {
    setLoading(true)
    try {
      let res = await GET(GET_ALL_USER_ADMIN);
      const { success, result } = res;
      if (success) {
        const data = result.map((el, i) => {
          return { rowNo: i + 1, ...el, fullName: `${el.firstname} ${el.surname}` };
        });
        setDataSource(data);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const openModal = (ref, record) => {
    if (ref === 'create') {
      setTitle('เพิ่มผู้ใช้งาน');
      setVisible(true);
    } else if (ref === 'edit') {
      setTitle('แก้ไขผู้ใช้งาน');
      setVisibleEdit(true);

      setValueDefault({ ...record, menu: record.menu });
    }
  };

  const blockUserAction = async (record) => {
    Modal.confirm({
      title: 'ยืนยันการทำรายการ',
      content: 'ต้องการทำรายการนี้ใช่หรือไม่',
      onOk() {
        actionBlock(record);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const actionBlock = async (record) => {
    try {
      setLoading(true);
      const obj = {
        _id: record._id,
        is_block: !record.is_block,
      };

      let res = await POST(EDIT_USER_ADMIN, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            getAllUserAdmin();
            // socket.emit('command-to-logout', {email: record.email, uid: record.uid});
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const closeModal = (ref) => {
    if (ref === 'create') {
      setVisible(false);
    } else if (ref === 'edit') {
      setVisibleEdit(false);
    }
  };

  return (
    <Wrapper>
      <Card>
        <h1>จัดการผู้ใช้งาน</h1>
        <Button
          style={{ backgroundColor: color.create, color: color.white }}
          size="large"
          className="ant-btn-customize"
          icon={<PlusOutlined />}
          onClick={() => openModal('create')}>
          {'เพิ่มผู้ใช้งาน'}
        </Button>
        <TableForm dataSource={dataSource} columns={ManageUserColumn({ openModal, blockUserAction, user })} bordered loading={loading} />
      </Card>
      <CreateUserModal visible={visible} title={title} onClose={closeModal} data={{ reApi: getAllUserAdmin, options: optionBranch }} />
      <EditUserModal
        visible={visibleEdit}
        title={title}
        onClose={closeModal}
        data={{ reApi: getAllUserAdmin, record: valueDefault, options: optionBranch }}
      />
    </Wrapper>
  );
};

export default ManageUser;