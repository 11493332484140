import axios from 'axios';
import _ from 'lodash';
import {APP_API_KEY, API_STORAGE_SERVICE} from '../../env';
import {refreshToken, setAccessToken, decodeUserAdmin} from '../../oauth';

const storageAuthInterceptor = async (req) => {
  const userAdminV2 = await decodeUserAdmin();
  if (!userAdminV2) return;

  const {accessToken, clientKey} = userAdminV2;

  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`;
    req.headers['x-api-key'] = APP_API_KEY;
    req.headers['client-key'] = clientKey;
  }
  return req;
};

export const STORAGE_API = axios.create({
  baseURL: API_STORAGE_SERVICE,
  timeout: 120000, // 2min
});

STORAGE_API.interceptors.request.use(storageAuthInterceptor);

STORAGE_API.interceptors.response.use(
  (response) => {
    return response; // ส่ง response กลับไปโดยปกติ
  },
  (error) => {
    // ถ้าเกิดข้อผิดพลาดจาก response
    if ((error.response && error.response.data.message === 'Token Expired') || error?.response?.data instanceof Blob) {
      // ทำการยิง API ใหม่โดยใช้ token ใหม่
      return getAccessTokenPromise()
        .then((accessToken) => {
          error.config.headers.Authorization = `Bearer ${accessToken}`;
          return axios.request(error.config);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  },
);

export const handleApiError = async (error) => {
  try {
    const errorMessage = error.response?.data?.message || error?.message || 'อัปโหลดไฟล์ไม่สำเร็จ';
    const data = null;
    return {error: errorMessage, data, originalError: error.response?.data};
  } catch (err) {
    throw new Error('อัปโหลดไฟล์ไม่สำเร็จ');
  }
};

let pendingAccessTokenPromise = null;

const getRefreshedAccessTokenPromise = async () => {
  try {
    const refreshedToken = await refreshToken();

    const dtRefreshToken = refreshedToken?.data?.data;
    const errRefreshToken = _.head(refreshedToken?.data?.errors);

    if (dtRefreshToken) {
      await setAccessToken(dtRefreshToken?.adminGetRefreshToken);

      return dtRefreshToken?.adminGetRefreshToken?.accessToken;
    } else {
      throw errRefreshToken;
    }
  } catch (error) {
    throw error;
  }
};

export const getAccessTokenPromise = async () => {
  if (!pendingAccessTokenPromise) {
    pendingAccessTokenPromise = getRefreshedAccessTokenPromise().finally(() => (pendingAccessTokenPromise = null));
  }

  return pendingAccessTokenPromise;
};
